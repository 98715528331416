<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <div class="tab-group">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="passwordInfo"
            :inputComponents="inputComponents"
          ></BaseTabGroup>
        </div>
      </template>
      <template #main> </template>
    </DetailPageLayout>
    <!-- 情報モーダル -->
    <InfoModal
      id="password-change-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="update-modal"
      :columnInfos="messageInfo"
      :message="updateMessage"
      @confirm="update(obj)"
    />
  </div>
</template>

<script>
import { openSubModal } from '@/common/Common.js'
import { goUpdate, update } from './PasswordChange.js'

export default {
  name: 'passwordChange',

  props: {},

  data() {
    return {
      obj: this,
      passwordInfo: {
        oldPassword: '',
        newPassword1: '',
        newPassword2: '',
      },
      updateMessage: null,
    }
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PASSWORD_CHANGE
      )('detail_ctrl_info')
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PASSWORD_CHANGE
      )('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](
            this.SCREEN_ID.PASSWORD_CHANGE
          ),
        },
      ]
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 更新ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.update,
            onClickCallback: () => {
              goUpdate(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },
    inputComponents() {
      return [
        {
          label: this.detailInfo.title.label,
          description: this.detailInfo.message.label,
          inputComponents: [
            {
              // 変更前パスワード
              type: 'password',
              id: 'oldPassword',
              columnInfo: this.detailInfo.old_password,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 変更後パスワード
              type: 'password',
              id: 'newPassword1',
              columnInfo: this.detailInfo.new_password1,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 変更後パスワードの再入力
              type: 'password',
              id: 'newPassword2',
              columnInfo: this.detailInfo.new_password2,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
          ],
        },
      ]
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.update.label
      )
    },
  },

  methods: {
    openSubModal,
    update,

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style lang="sass" scoped></style>
