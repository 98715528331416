import { render, staticRenderFns } from "./LogManagementList.vue?vue&type=template&id=1ba0a712&scoped=true&"
import script from "./LogManagementList.vue?vue&type=script&lang=js&"
export * from "./LogManagementList.vue?vue&type=script&lang=js&"
import style0 from "./LogManagementList.vue?vue&type=style&index=0&id=1ba0a712&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ba0a712",
  null
  
)

export default component.exports