/**
 * ダッシュボード
 */
export default class dashboard {
  //集計実行日時
  executeDateTime = null

  //要修正受注件数
  needUpdateOrderCount

  //変更・削除オーダー件数
  updateOrDeleteOrderCount

  //発注承認差し戻し件数
  orderPayApproveReturnCount

  //請求支払承認差し戻し件数
  requestPayApproveReturnCount

  //発注辞退件数
  orderRejectCount

  // 10：新規受注件数
  newReceivedOrderLabels = []
  newReceivedOrderValues = []

  // 11：輸送オーダー生成件数
  newShippingOrderLabels = []
  newShippingOrderValues = []

  // 12：要修正受注オーダー件数
  correctionRequiredOrderLabels = []
  correctionRequiredOrderValues = []

  // 13：変更削除オーダー件数
  changedOrderLabels = []
  changedOrderValues = []

  // 20：顧客ごとの配車情報
  customerPlanningOrderLabels = []
  customerPlanningOrderDataSet = []

  // 21：未割付件数
  nonAllocatedLabels = []
  nonAllocatedValues = []

  // 30：配車情報
  operationLabels = []
  operationDataSet = []

  // 31：発注情報
  orderInfoLabels = []
  orderInfoDataSet = []

  // 32：承諾情報
  approveOrderLabels = []
  approveOrderDataSet = []

  // 40：輸送完了状況
  transportCompleteLabels = []
  transportCompleteDataSet = []

  // 50：積込済み情報
  loadingFinishedLabels = []
  loadingFinishedDataSet = []

  // 51：搬入済み情報
  carriedInLabels = []
  carriedInDataSet = []
}
