/**
 * ログイン要求情報.
 *
 * @file system/authentication/loginRequest.js
 * @author Baek.JH (SBK)
 * @copyright Hitach Industry & Control Solutions, Ltd. All rights reserved.
 */

export default class LoginRequest {
  constructor(userId, password) {
    this.userId = userId
    this.password = password
  }
}
