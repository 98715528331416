import NoticeService from '@/system/notice/NoticeService'

export function getNoticeCount(obj) {
  let noticeCount = 0
  obj.$store.dispatch('init/setServiceLoading')
  NoticeService.getAll()
    .then(
      (response) => {
        if (response.data == null) {
          noticeCount = 0
        } else {
          //インフォメーション件数
          noticeCount = response.data.length
        }
      },
      (e) => {
        noticeCount = 0

        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        console.log('msg=>' + msg)
        // openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
      obj.$store.dispatch('init/setNoticeCount', noticeCount)
      //console.log('getNoticeCount=' + noticeCount)
    })
}
