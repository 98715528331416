<template>
  <div data-class="list">
    <BaseSearchListPage
      ref="information-list"
      :breadcrumbItems="breadcrumbItems"
      :searchCondition="searchCondition"
      :searchConditionInfo="searchConditionInfo"
      :buttonItems="buttonItems"
      :tableItems="tableItems"
      :fields="fields"
      selectMode="range"
      @row-selected="onRowSelected"
      @max-count-changed="onMaxCountChanged"
      @clear="clear(obj)"
      @search="search(obj)"
    >
      <!-- <template #search-condition-ex>
        <div class="data-group-role">
          <div>
            <BaseLabel
              class="baseLabel"
              :columnInfo="resultControlInfo.detail"
            />
            <div class="row">
              <div v-if="ctrlItems" class="mr">
                <BaseButton
                  class="btn-2"
                  v-for="(item, index) in ctrlItems[0]"
                  :key="index"
                  :variant="item.variant"
                  :icon="item.icon"
                  :columnInfo="item.columnInfo"
                  :disabled="item.disabled"
                  @click="item.onClickCallback"
                />
              </div>
            </div>
            <section data-class="work-list-table">
              <b-table
                data-class="base-table"
                class="table table-bordered base-table"
                select-mode="single"
                bordered
                hover
                no-border-collapse
                responsive
                small
                sticky-header
                selectable
                outlined
                :fields="searchFields"
                :items="stowageTableItems"
                @row-selected="onRowStowageSelected"
                v-bind="$attrs"
                v-on="$listeners"
              >
                <template v-slot:cell(key1)="data">
                  {{ data.index + 1 }}
                </template>
                <template v-slot:cell(zone_priority_order)="row">
                  <b-form-input
                    v-model="row.item.zone_priority_order"
                    size="sm"
                    type="number"
                    v-bind="$attrs"
                    v-on="$listeners"
                  />
                </template>
              </b-table>
            </section>
          </div>
        </div>
      </template> -->
    </BaseSearchListPage>

    <!-- 削除モーダル -->
    <ConfirmModal
      id="remove-modal"
      :columnInfos="messageInfo"
      :message="removeMessage"
      @confirm="remove(obj)"
    />
    <!-- 部署マスタモダール-->
    <DepartmentModal
      id="department-modal"
      type="entry"
      selectMode="range"
      :useUnset="false"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="addDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  openSubModal,
} from '@/common/Common.js'
import { SCREEN_ID } from '@/common/const.js'
import {
  init,
  search,
  clear,
  goDetail,
  goNew,
  goCopy,
  goRemove,
  addDepartment,
  remove,
  removeDepartment,
} from './InformationList.js'

const screenId = SCREEN_ID.INFORMATION_LIST

export default {
  props: {
    isReSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableItems: [],
      selectedItem: [],
      dataGroupRoleItems: [],
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      removeMessage: null,
      stowageTableItems: [],
      stowageSelectedItem: [],
      departmentModalSearchCondition: {},
    }
  },
  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
  },
  created() {
    init(this)
  },

  computed: {
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        screenId
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 表示期間
            type: 'dateTimeFromTo',
            dateFromId: 'displayBeginDate',
            timeFromId: 'displayBeginTime',
            dateToId: 'displayEndDate',
            timeToId: 'displayEndTime',
            dateFromColumnInfo: searchConditionInfo.display_begin_date,
            timeFromColumnInfo: searchConditionInfo.display_begin_time,
            dateToColumnInfo: searchConditionInfo.display_end_date,
            timeToColumnInfo: searchConditionInfo.display_end_time,
            dateFromRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            timeFromRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            dateToRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            timeToRules: [this.INPUT_TYPE_CHECK.REQUIRED],
          },
          {
            // 有効無効
            type: 'select',
            id: 'useFlag',
            columnInfo: searchConditionInfo.use_flag,
            options: getListOptions(this.MASTER_CODE.USE_FLAG),
            onChangeCallback: () => {},
          },
          {
            // 開示レベル
            type: 'select',
            id: 'informationReleaseLevel',
            columnInfo: searchConditionInfo.information_release_level,
            options: getListOptions(this.MASTER_CODE.INFORMATION_RELEASE_LEVEL),
            onChangeCallback: () => {},
          },
          {
            // 登録者区分
            type: 'select',
            id: 'entryUserKind',
            columnInfo: searchConditionInfo.entry_user_kind,
            options: getListOptions(this.MASTER_CODE.ENTRY_USER_KIND),
            onChangeCallback: () => {},
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    fileInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'file'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    searchFields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.SEARCH_FIELDS
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 詳細ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.detail,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goDetail(this)
            },
          },
          // 新規ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.new,
            onClickCallback: () => {
              goNew(this)
            },
          },
          // コピーボタン
          {
            variant: 'primary',
            icon: 'copy',
            columnInfo: this.resultControlInfo.copy,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goCopy(this)
            },
          },
          // 削除ボタン
          {
            variant: 'primary',
            icon: 'times',
            columnInfo: this.resultControlInfo.delete,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goRemove(this)
            },
          },
        ],
      ]
    },

    ctrlItems() {
      return [
        [
          // 部署追加ボタン
          {
            variant: 'success',
            icon: 'plus-circle',
            columnInfo: this.resultControlInfo.add,
            onClickCallback: () => {
              this.isStowagePlaceClick = true
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind = null
              openSubModal(this, 'department-modal')
            },
          },
          // 削除ボタン
          {
            variant: 'success',
            icon: 'minus-circle',
            columnInfo: this.resultControlInfo.delete,
            onClickCallback: () => {
              removeDepartment(this)
            },
          },
        ],
      ]
    },
    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    search,
    clear,
    remove,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      let today = this.$moment(new Date()).format('YYYY-MM-DD')
      let currentTime00 = '00:00'
      let currentTime23 = '23:59'

      return {
        displayBeginDate: today,
        displayBeginTime: currentTime00,
        displayEndDate: today,
        displayEndTime: currentTime23,

        useFlag: null,
        informationReleaseLevel: null,
        entryUserKind: null,
        sectionIds: [],
        sectionNames: [],
        size: getSearchMaxCount(),
        screenId: screenId,
        domainName: 'result_fields',
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    onMaxCountChanged(maxCount) {
      this.searchCondition.size = maxCount
      // this.retrievePlaces()
    },

    addDepartment(id, code, name) {
      addDepartment(this, id, code, name)
    },
    onRowStowageSelected(selectedItems) {
      this.stowageSelectedItem = selectedItems
    },
  },
}
</script>

<style scoped>
#footer {
  bottom: 0;
  position: fixed;
  width: 96.8%;
  height: 50px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
  /* margin-left:300px; */
  text-align: center;
}
.baseLabel {
  font-weight: bold;
}
.mr {
  margin-left: 16px;
  margin-bottom: 5px;
}
.btn-2 {
  margin-right: 8px;
}
</style>
