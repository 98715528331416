<template>
  <div data-class="list">
    <BaseSearchListPage
      :breadcrumbItems="breadcrumbItems"
      :searchCondition="searchCondition"
      :searchConditionInfo="searchConditionInfo"
      :buttonItems="buttonItems"
      :tableItems="tableItems"
      :fields="fields"
      selectMode="range"
      @row-selected="onRowSelected"
      @max-count-changed="onMaxCountChanged"
      @clear="clear(obj)"
      @search="search(obj)"
    >
    </BaseSearchListPage>

    <!-- CSV出力モーダル -->
    <ConfirmModal
      id="download-modal"
      :columnInfos="messageInfo"
      :message="downloadMessage"
      @confirm="download(obj)"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  openSubModal,
} from '@/common/Common.js'
import { SCREEN_ID, LARGE_LOG } from '@/common/const.js'
import {
  init,
  search,
  clear,
  download,
} from '@/system/logmanagement/LogManagementList.js'

const screenId = SCREEN_ID.LOGMANAGEMENT_LIST

export default {
  props: {
    isReSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let initMiddleKindData = (this.listOptions = getListOptions(
      this.MASTER_CODE.MIDDLE_KIND_BATCH
    ))
    return {
      tableItems: [],
      selectedItem: [],
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      removeMessage: null,
      listOptions: initMiddleKindData,
    }
  },

  created() {
    init(this)
  },

  computed: {
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        screenId
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 発生日時From
            type: 'dateTime',
            dateId: 'eventDateFrom',
            timeId: 'eventTimeFrom',
            dateColumnInfo: searchConditionInfo.event_date_from,
            timeColumnInfo: searchConditionInfo.event_time_from,
            dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            timeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
          },
          {
            // 発生日時To
            type: 'dateTime',
            dateId: 'eventDateTo',
            timeId: 'eventTimeTo',
            dateColumnInfo: searchConditionInfo.event_date_to,
            timeColumnInfo: searchConditionInfo.event_time_to,
            dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            timeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
          },
          {
            // 大区分
            type: 'select',
            id: 'largeKind',
            columnInfo: searchConditionInfo.large_kind,
            options: getListOptions(this.MASTER_CODE.LARGE_KIND, false),
            rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            onChangeCallback: () => {
              // 操作履歴    大区分(large_kind) = 10 →中区分= ? (TP -> middle_kind_batch)
              // バッチ        大区分(large_kind) = 20 →中区分=middle_kind_batch
              // 通信          大区分(large_kind) = 30 →中区分=middle_kind_if
              // ログイン      大区分(large_kind) = 40 →中区分=middle_kind_login
              // マスタ履歴  大区分(large_kind) = 50 →中区分=middle_kind_master
              switch (this.searchCondition.largeKind) {
                case LARGE_LOG.OPE: //'10':
                case LARGE_LOG.BATCH: //'20':
                  this.listOptions = getListOptions(
                    this.MASTER_CODE.MIDDLE_KIND_BATCH
                  )
                  break
                case LARGE_LOG.TERMINAL: //'30':
                  this.listOptions = getListOptions(
                    this.MASTER_CODE.MIDDLE_KIND_IF
                  )
                  break
                case LARGE_LOG.LOGIN: //'40':
                  this.listOptions = getListOptions(
                    this.MASTER_CODE.MIDDLE_KIND_LOGIN
                  )
                  break
                case LARGE_LOG.MASTER: //'50':
                  this.listOptions = getListOptions(
                    this.MASTER_CODE.MIDDLE_KIND_MASTER
                  )
                  break
                default:
                  this.listOptions = []
                  break
              }
            },
          },
          {
            // 中区分
            type: 'select',
            id: 'middleKind',
            columnInfo: searchConditionInfo.middle_kind,
            //options: getListOptions(this.MASTER_CODE.MIDDLE_KIND_MASTER),
            options: this.getMiddleListOptions,
            onChangeCallback: () => {},
          },
          {
            // メッセージ区分
            type: 'select',
            id: 'messageKind',
            columnInfo: searchConditionInfo.message_kind,
            options: getListOptions(this.MASTER_CODE.MESSAGE_KIND),
            onChangeCallback: () => {},
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    fileInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'file'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [],
        // ボタングループ#2
        [
          // ﾌｧｲﾙ出力ボタン
          {
            variant: 'primary',
            icon: 'download',
            columnInfo: this.resultControlInfo.file_print,
            onClickCallback: () => {
              openSubModal(this, 'download-modal')
            },
          },
        ],
      ]
    },

    /**
     * メッセージ.
     * @return {String}
     */
    downloadMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG994Q',
        this.resultControlInfo.file_print.label
      )
    },

    getMiddleListOptions() {
      return this.listOptions
    },

    // /**
    //  * 単数選択しているか.
    //  * @return {Boolean}
    //  */
    // isSelected() {
    //   return this.selectedItem.length > 0
    // },
  },

  methods: {
    getListOptions,
    openSubModal,
    search,
    clear,
    download,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      let today = this.$moment(new Date()).format('YYYY-MM-DD')
      //let currentTime = this.$moment(new Date()).format('HH:mm')
      let currentTime00 = '00:00'
      let currentTime23 = '23:59'

      return {
        eventDateFrom: today,
        eventTimeFrom: currentTime00,
        eventDateTo: today,
        eventTimeTo: currentTime23,
        largeKind: LARGE_LOG.BATCH,
        middleKind: null,
        messageKind: null,
        deleted: null,
        size: getSearchMaxCount(),
        screenId: screenId,
        domainName: 'result_fields',
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    onMaxCountChanged(maxCount) {
      this.searchCondition.size = maxCount
      // this.retrievePlaces()
    },

    // setUploadFile(file) {
    //   upload(this, file)
    // },
  },
}
</script>

<style scoped>
#footer {
  bottom: 0;
  position: fixed;
  width: 96.8%;
  height: 50px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
  /* margin-left:300px; */
  text-align: center;
}
</style>
