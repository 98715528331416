/**
 * インフォメーションデータ
 * @class
 * @exports
 */
export default class Information {
  informationId = null
  informationDetail = null
  blockCode = null
  blockName = null
  displayBeginDate = null
  displayEndDate = null
  displayBeginTime = null
  displayEndTime = null
  displayBeginDateTime = null
  displayEndTiDateTime = null
  useFlag = null
  useFlagName = null
  informationReleaseLevel = null
  informationReleaseLevelName = null
  importance = null
  entryUserKind = null
  entryUserKindName = null
  entryUserName = null
  changeUserName = null
  note = null
  sectionIds = []
  deleted = 0
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  version = null
  updateByTrigger = null
}
