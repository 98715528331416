<template>
  <div class="main-padding">
    <h1>NEUPLANET</h1>
    <p></p>
    <h3>Third Party Software License/Notices</h3>
    <p>
      このドキュメントには、<strong>NEUPLANET</strong>に関するサードパーティのソフトウェア通知および/または追加の利用規約が含まれています。
      特定のライセンスおよびライセンス通知は、ライセンス要件に従って、製品配布の他の部分に表示される場合があります。
      <strong>NEUPLANET</strong>は、<samp
        >Hitach Industry &amp; Control Solutions, Ltd.</samp
      >
      の登録商標です。
      その他すべてのサードパーティの商標は、それぞれの所有者に帰属します。
    </p>
    <p>
      This document contains third party Software Notices and/or Additional
      Terms and Conditions for <strong>NEUPLANET</strong>. Certain licenses and
      license notices may appear in other parts of the product distribution in
      accordance with the license requirements. <strong>NEUPLANET</strong>, is a
      registered trademark of Hitach Industry &amp; Control Solutions, Ltd.. All
      other third-party trademarks are the property of their respective owners.
    </p>
    <b-list-group>
      <b-list-group-item
        href="#"
        v-for="(license, key) in licenses"
        :key="key"
        class="flex-column align-items-start"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">🗹 {{ key }}</h5>
          <small></small>
        </div>

        <p class="mb-1" v-if="key !== license.license_name">
          {{ license.license_name }}
        </p>

        <small>{{ license.license_text }}</small>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import licensesJson from '@/assets/licenses'
export default {
  name: 'License',
  data() {
    return {
      licenses: licensesJson,
    }
  },
}
</script>

<style lang="scss" scoped></style>
