import {
  openMsgModal,
  downloadCSV,
  formatYYYYMMDD,
  formatHHmmss,
  csvFileName,
  displayYYYYYMMDDHHmmss,
} from '@/common/Common.js'
import { SCREEN_ID, MSG_TYPE } from '@/common/const.js'
import LogManagementService from './LogManagementService'

const screenId = SCREEN_ID.LOGMANAGEMENT_LIST

export function init(obj) {
  // サーバーの処理後再検索
  if (obj.isReSearch == true) {
    reSearch(obj)
  }
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

/**
 * 検索します.
 * @param {*} obj
 */
export function search(obj) {
  obj.searchCondition.eventDateFrom = formatYYYYMMDD(
    obj,
    obj.searchCondition.eventDateFrom
  )
  obj.searchCondition.eventTimeFrom = formatHHmmss(
    obj.searchCondition.eventTimeFrom
  )
  obj.searchCondition.eventDateTo = formatYYYYMMDD(
    obj,
    obj.searchCondition.eventDateTo
  )
  obj.searchCondition.eventTimeTo = formatHHmmss(
    obj.searchCondition.eventTimeTo
  )

  obj.$store.dispatch('init/setServiceLoading')
  LogManagementService.search(obj.searchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList
        obj.tableItems.forEach((element) => {
          element.event_time = displayYYYYYMMDDHHmmss(obj, element.event_time)
        })
        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()
  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

// 「CSV出力」ボタンクリック
export function download(obj) {
  obj.$bvModal.hide('download-modal')

  obj.searchCondition.eventDateFrom = formatYYYYMMDD(
    obj,
    obj.searchCondition.eventDateFrom
  )
  obj.searchCondition.eventTimeFrom = formatHHmmss(
    obj.searchCondition.eventTimeFrom
  )
  obj.searchCondition.eventDateTo = formatYYYYMMDD(
    obj,
    obj.searchCondition.eventDateTo
  )
  obj.searchCondition.eventTimeTo = formatHHmmss(
    obj.searchCondition.eventTimeTo
  )

  //obj.searchCondition.filename = 'SC01F001.csv'
  let newCsvName = csvFileName(obj, screenId)
  obj.searchCondition.filename = newCsvName

  obj.$store.dispatch('init/setServiceLoading')
  LogManagementService.download(obj.searchCondition)
    .then(
      (response) => {
        if (response.data == '') {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        } else {
          downloadCSV(obj.searchCondition.filename, response.data)
        }
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, obj.MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}
