import { createAuthenticaionService, makeQueryParams } from '@/services/index'

const BASE_URL = `${process.env.VUE_APP_API_URL}users/`
const BASE_BLOCK_URL = `${process.env.VUE_APP_API_URL}`

class UserService {
  getAll() {
    return createAuthenticaionService(BASE_URL).get('/')
  }

  get(id) {
    return createAuthenticaionService(BASE_URL).get(`/${id}/`)
  }

  create(data) {
    return createAuthenticaionService(BASE_URL).post('/', data)
  }

  update(id, data) {
    return createAuthenticaionService(BASE_URL).put(`/${id}/`, data)
  }

  initPassword(id, data) {
    return createAuthenticaionService(`${BASE_URL}init-password/`).put(
      `/${id}/`,
      data
    )
  }

  changePassword(id, data) {
    return createAuthenticaionService(`${BASE_URL}change-password/`).put(
      `/${id}/`,
      data
    )
  }

  deleteLogical(id, data) {
    return createAuthenticaionService(`${BASE_URL}delete/`).put(`/${id}/`, data)
  }

  delete(id) {
    return createAuthenticaionService(BASE_URL).delete(`/${id}/`)
  }

  deleteAll() {
    return createAuthenticaionService(BASE_URL).delete('/')
  }

  removeList(data) {
    return createAuthenticaionService(`${BASE_URL}removelist/`).put('/', data)
  }

  search(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)
  }

  getLowerBlocks(blockCode) {
    return createAuthenticaionService(`${BASE_BLOCK_URL}lower-blocks`).get(
      `/${blockCode}`
    )
  }

  download(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}download/`).get(`/?${param}`)
  }
}

export default new UserService()
