import { openMsgModal, openSubModal, getMasterName } from '@/common/Common.js'
import { MSG_TYPE, DETAIL_SCREEN_KBN, MASTER_CODE } from '@/common/const.js'
import UserService from './UserService'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    UserService.get(obj.userId)
      .then(
        (response) => {
          obj.formData = response.data
          obj.dataGroupRoleItems = obj.formData.blockRoles
            .map((e) => ({
              ['data_group']: e.blockCode,
              ['data_group_name']: e.blockName,
              ['role']: e.roleId,
              ['role_name']: e.roleName,
            }))
            .sort(function (a, b) {
              let valueA = a.data_group.toUpperCase()
              let valueB = b.data_group.toUpperCase()
              if (valueA < valueB) {
                return -1
              }
              if (valueA > valueB) {
                return 1
              }
              return 0
            })
          setDefaultBlock(obj)
          obj.oriData = _.cloneDeep(obj.formData)
          obj.oriDataGroupRoleItems = _.cloneDeep(obj.dataGroupRoleItems)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

export function goInitPassword(obj) {
  obj.initPasswordMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.init_password.label
  )
  openSubModal(obj, 'init-password-modal')
}

export async function initPassword(obj) {
  obj.$bvModal.hide('init-password-modal')

  let registerData = _.cloneDeep(obj.formData)

  obj.$store.dispatch('init/setServiceLoading')
  UserService.initPassword(registerData.userId, registerData)
    .then(
      (response) => {
        obj.formData = response.data

        // 情報モーダル表示
        openSubModal(obj, 'user-detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(this, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  if (obj.dataGroupRoleItems.length === 0) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG935E')
    )
  } else {
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.detailCtrlInfo.register.label
    )
    openSubModal(obj, 'register-modal')
  }
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  let registerData = _.cloneDeep(obj.formData)

  registerData.blockRoles = obj.dataGroupRoleItems.map((e) => ({
    ['blockCode']: e.data_group,
    ['roleId']: e.role,
  }))

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = UserService.create(registerData)
  } else {
    // 詳細
    callApi = UserService.update(registerData.userId, registerData)
  }

  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'user-detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(this, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function clear(obj) {
  obj.formData = _.cloneDeep(obj.oriData)
  obj.dataGroupRoleItems = _.cloneDeep(obj.oriDataGroupRoleItems)

  obj.selectedDataGroup = null
  obj.selectedRole = null
  obj.includeDescendants = false

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

export function add(obj) {
  if (!!obj.selectedDataGroup && !!obj.selectedRole) {
    if (obj.includeDescendants) {
      obj.$store.dispatch('init/setServiceLoading')
      UserService.getLowerBlocks(obj.selectedDataGroup)
        .then(
          (response) => {
            const blocks = response.data.sort(function (a, b) {
              let valueA = a.blockCode.toUpperCase()
              let valueB = b.blockCode.toUpperCase()
              if (valueA < valueB) {
                return -1
              }
              if (valueA > valueB) {
                return 1
              }
              return 0
            })
            blocks.forEach((block) => {
              const item = obj.dataGroupRoleItems.find(
                (e) => e.data_group === block.blockCode
              )
              if (!item) {
                obj.dataGroupRoleItems.push({
                  data_group: block.blockCode,
                  data_group_name: block.blockName,
                  role: obj.selectedRole,
                  role_name: getMasterName(MASTER_CODE.ROLE, obj.selectedRole),
                })
              }
            })
            setDefaultBlock(obj)
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
    } else {
      const item = obj.dataGroupRoleItems.find(
        (e) => e.data_group === obj.selectedDataGroup
      )
      if (!item) {
        obj.dataGroupRoleItems.push({
          data_group: obj.selectedDataGroup,
          data_group_name: getMasterName(
            MASTER_CODE.BLOCK,
            obj.selectedDataGroup
          ),
          role: obj.selectedRole,
          role_name: getMasterName(MASTER_CODE.ROLE, obj.selectedRole),
        })
        setDefaultBlock(obj)
      }
    }
  }
}

export function remove(obj) {
  if (obj.selectedItem) {
    const index = obj.dataGroupRoleItems.indexOf(obj.selectedItem)
    obj.dataGroupRoleItems.splice(index, 1)
    setDefaultBlock(obj)
  }
}

export function updateRole(obj) {
  if (obj.selectedItem && obj.selectedDataGroup && obj.selectedRole) {
    const sameBlockItem = obj.dataGroupRoleItems.find(
      (e) => e !== obj.selectedItem && e.data_group === obj.selectedDataGroup
    )
    if (!sameBlockItem) {
      obj.selectedItem.data_group = obj.selectedDataGroup
      obj.selectedItem.data_group_name = getMasterName(
        MASTER_CODE.BLOCK,
        obj.selectedDataGroup
      )
      obj.selectedItem.role = obj.selectedRole
      obj.selectedItem.role_name = getMasterName(
        MASTER_CODE.ROLE,
        obj.selectedRole
      )
      setDefaultBlock(obj)
    }
  }
}

export function setDefaultBlock(obj) {
  if (obj.dataGroupRoleItems.length > 0) {
    const item = obj.dataGroupRoleItems.find(
      (e) => e.data_group === obj.formData.defaultBlockCode
    )
    if (!item) {
      obj.formData.defaultBlockCode = obj.dataGroupRoleItems[0].data_group
    }
  } else {
    obj.formData.defaultBlockCode = null
  }
}
