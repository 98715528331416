import { createAuthenticaionService } from '@/services/index'

const BASE_URL = `${process.env.VUE_APP_API_URL}information/newinfo`
const BASE_URL2 = `${process.env.VUE_APP_API_URL}notice/update`
class NoticeService {
  getAll() {
    return createAuthenticaionService(BASE_URL).get('/')
  }

  update(datas) {
    return createAuthenticaionService(BASE_URL2).put('/', datas)
  }
}

export default new NoticeService()
