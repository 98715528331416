<template>
  <div data-class="index-page">
    <BaseOverlayLoading :show="getServiceLoading" />
    <b-navbar class="navbar" fixed type="light" variant="light">
      <div class="d-flex container-fluid full-width">
        <b-navbar-brand data-class="navbar-brand">
          <img :src="brandUrl" alt="Neuplanet" />
          <span class="navbar-title">
            {{ titleInfo.title.label }}
          </span>
        </b-navbar-brand>
        <b-navbar-nav class="navbar navbar-expand-xl">
          <div class="d-flex flex-wrap">
            <BaseNavDropdown
              v-for="(group, index) in bizNavigationInfo"
              :key="index"
              :navGroup="group"
            >
            </BaseNavDropdown>
          </div>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto navbar navbar-expand-xl">
          <div class="d-flex flex-wrap">
            <div>
              <!-- HOMEボタン -->
              <b-nav-item data-class="nav-item" to="/home">
                <BaseIcon name="home" />
              </b-nav-item>
            </div>
            <div>
              <!-- 通知ボタン -->
              <b-nav-item
                v-if="useNoticeByRole && existNotice"
                data-class="nav-item"
                @click="displayNotice"
              >
                <b-icon
                  icon="exclamation-circle-fill"
                  animation="fade"
                  font-scale="1"
                  variant="info"
                ></b-icon>
              </b-nav-item>
            </div>

            <div v-for="(group, index) in systemNavigationInfo" :key="index">
              <BaseNavDropdown :navGroup="group" right> </BaseNavDropdown>
            </div>
          </div>
        </b-navbar-nav>
      </div>
    </b-navbar>

    <transition name="fade" mode="out-in">
      <router-view
        class="container-fluid"
        @set-error-msg="setErrorMsg"
        @set-warn-msg="setWarnMsg"
        @set-info-msg="setInfoMsg"
      ></router-view>
    </transition>

    <!-- エラーモーダル -->
    <ErrorModal
      id="error-modal"
      :columnInfos="messageInfo"
      :message="errorMessage"
    />
    <!-- 警告モーダル -->
    <WarnModal
      id="warn-modal"
      :columnInfos="messageInfo"
      :message="warnMessage"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
    />
    <!-- インフォメーションモーダル -->
    <NoticeModal
      id="notice-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @notify-confirm="afterCloseSetNotice"
    />
  </div>
</template>

<script>
import { SCREEN_ID } from '@/common/const.js'
import { openSubModal } from '@/common/Common.js'
import { getNoticeCount } from '@/system/main/IndexPage.js'
import AuthenticationService from '@/system/authentication/AuthenticationService'
import ManualPlanningService from '@/plan/manualPlanning/ManualPlanningService'

export default {
  name: 'IndexPage',
  components: {
    NoticeModal: () => import('@/system/notice/NoticeModal.vue'),
  },

  data() {
    return {
      errorMessage: null,
      warnMessage: null,
      infoMessage: null,
      // //自動更新
      // //interval
      // noticeInterval: null,
      // intervalCount: 0,
      obj: this,
    }
  },

  created() {
    //getNoticeCount(this)
    // //自動更新
    // this.intervalNoticeCheck()
  },

  computed: {
    titleInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.MENU)(
        'title'
      )
    },
    userInfo() {
      return (
        this.$store.getters['authentication/getUserName'] +
        '(' +
        this.$store.getters['authentication/getBlockName'] +
        ')'
      )
    },
    menuGroupInfo() {
      let info = this.$store.getters['init/getScreens'](this.SCREEN_ID.MENU)(
        'menu'
      )

      // HOME: アイコンのみ
      info.menu_8.label = ''
      // 通知: アイコンのみ
      info.menu_9.label = ''
      // ユーザ: ログインユーザ名を表示する。
      info.menu_10.label = this.userInfo
      // 設定: アイコンのみ
      info.menu_11.label = ''

      return info
    },
    menuItemInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.MENU)('item')
    },
    bizNavigationInfo() {
      let groups = this.menuGroupInfo
      let items = this.menuItemInfo
      let navInfo = []
      navInfo = [
        {
          // 配車
          columnInfo: groups.menu_1,
          items: [
            // 受注オーダー
            {
              columnInfo: items.item_1_1,
              screenId: SCREEN_ID.RECEIVE_ORDER_LIST, //'C001-10'
            },
            // 配車オーダー
            {
              columnInfo: items.item_1_2,
              screenId: SCREEN_ID.PLANNING_ORDER_LIST, //'D001-10'
            },
            // 稼動情報
            {
              columnInfo: items.item_1_3,
              screenId: SCREEN_ID.VEHICLE_OPERATION_LIST, //'D004-10'
            },
            // 自動配車計画指示
            {
              columnInfo: items.item_1_4,
              screenId: SCREEN_ID.AUTO_PLANNING_LIST, //'D005-10'
            },
            // 手動配車
            {
              columnInfo: items.item_1_5,
              screenId: SCREEN_ID.MANUAL_PLANNING_SEARCH, //'D006-10'
            },
            // 発注
            {
              columnInfo: items.item_1_6,
              screenId: SCREEN_ID.PLACE_ORDER_LIST, //'E001-10'
            },
          ],
        },
        {
          // 輸送
          columnInfo: groups.menu_2,
          items: [
            // 荷積荷卸
            {
              columnInfo: items.item_2_1,
              screenId: SCREEN_ID.WORK_RECORD_LIST, //'F001-10'
            },
          ],
        },
        {
          // 請求支払
          columnInfo: groups.menu_3,
          items: [
            // 請求
            {
              columnInfo: items.item_3_1,
              screenId: SCREEN_ID.BILLING_LIST, //'G001-10'
            },
            // 支払
            {
              columnInfo: items.item_3_2,
              screenId: SCREEN_ID.PAYMENT_LIST, //'G002-10'
            },
          ],
        },
        {
          // 定義設定
          columnInfo: groups.menu_4,
          items: [
            // 定期オーダー生成設定
            {
              columnInfo: items.item_4_1,
              screenId: SCREEN_ID.REGULAR_ORDER_LIST, //'C002-10'
            },
            // 配車計画パターン
            {
              columnInfo: items.item_4_2,
              screenId: SCREEN_ID.PLANNING_PATTERN_LIST, //'D002-10'
            },
            // 配車計画評価設定
            {
              columnInfo: items.item_4_3,
              screenId: SCREEN_ID.PLANNING_EVALUATION_LIST, //'D003-10'
            },
            // 輸送手段グループ
            {
              columnInfo: items.item_4_4,
              screenId: SCREEN_ID.VEHICLE_GROUP_LIST, //'H004-10'
            },
          ],
        },
        {
          // マスタ
          columnInfo: groups.menu_5,
          items: [
            // 部署
            {
              columnInfo: items.item_5_1,
              screenId: SCREEN_ID.DEPARTMENT_LIST, //'H001-10'
            },
            // 場所
            {
              columnInfo: items.item_5_2,
              screenId: SCREEN_ID.PLACE_LIST, //'H002-10'
            },
            // 輸送手段
            {
              columnInfo: items.item_5_3,
              screenId: SCREEN_ID.VEHICLE_LIST, //'H003-10'
            },
            // 荷姿パターン
            {
              columnInfo: items.item_5_4,
              screenId: SCREEN_ID.PRODUCT_PATTERN_LIST, //'H005-10'
            },
            // 荷姿
            {
              columnInfo: items.item_5_5,
              screenId: SCREEN_ID.PRODUCT_LIST, //'H006-10'
            },
            // 稼動時間パターン
            {
              columnInfo: items.item_5_6,
              screenId: SCREEN_ID.OPERATION_TIME_PATTERN_LIST, //'H009-10'
            },
            // 方面・コース
            {
              columnInfo: items.item_5_7,
              screenId: SCREEN_ID.AREA_LIST, //'H010-10'
            },
            // カレンダー
            {
              columnInfo: items.item_5_8,
              screenId: SCREEN_ID.CALENDAR_LIST, //'H011-10'
            },
            // 区間分割パターン
            {
              columnInfo: items.item_5_9,
              screenId: SCREEN_ID.SECTION_DIVISION_LIST, //'H012-10'
            },
            // タリフ
            {
              columnInfo: items.item_5_10,
              screenId: SCREEN_ID.TARIFF_LIST, //'H013-10'
            },
            // 運賃項目
            {
              columnInfo: items.item_5_11,
              screenId: SCREEN_ID.FREIGHT_ITEM_LIST, //'H014-10'
            },
            // 特殊車両通行許可
            {
              columnInfo: items.item_5_12,
              screenId: SCREEN_ID.PASSAGE_PERMIT_LIST, //'H015-10'
            },
            // 実車番
            {
              columnInfo: items.item_5_13,
              screenId: SCREEN_ID.REAL_VEHICLE_LIST, //'H016-10'
            },
          ],
        },
        {
          // 協力会社
          columnInfo: groups.menu_6,
          items: [
            // 運送依頼
            {
              columnInfo: items.item_6_1,
              screenId: SCREEN_ID.TRANSPORT_REQUEST_LIST, //'E002-10'
            },
            // 運賃検索
            {
              columnInfo: items.item_6_2,
              screenId: SCREEN_ID.FARE_LIST, //'G003-10'
            },
          ],
        },
        {
          // シミュレーション
          columnInfo: groups.menu_7,
          items: [
            // シミュレーションデータ生成
            {
              columnInfo: items.item_7_1,
              screenId: SCREEN_ID.SIMULATION_DATA_GENERATION, //'I001'
            },
          ],
        },
      ]

      return this.navInfoRole(navInfo)
    },

    systemNavigationInfo() {
      let groups = this.menuGroupInfo
      let items = this.menuItemInfo
      let navInfo = [
        {
          // ユーザー
          columnInfo: groups.menu_10,
          icon: 'user',
          items: [
            // ブロック選択
            {
              columnInfo: items.item_10_1,
              icon: 'home',
              screenId: SCREEN_ID.BLOCK_SELECT, //'B002-00'
            },
            // 言語選択
            {
              columnInfo: items.item_10_2,
              icon: 'language',
              screenId: SCREEN_ID.LANG_SELECT, //'B002-10'
            },
            // パスワードの変更
            {
              columnInfo: items.item_10_3,
              icon: 'lock',
              screenId: SCREEN_ID.PASSWORD_CHANGE, //'B005-21'
            },
            // ログアウト
            {
              columnInfo: items.item_10_4,
              icon: 'sign-out-alt',
              screenId: null,
              onClickCallback: () => {
                this.logout()
              },
            },
          ],
        },
        {
          // 設定
          columnInfo: groups.menu_11,
          icon: 'cog',
          items: [
            // ログ
            {
              columnInfo: items.item_11_1,
              icon: 'list-alt',
              screenId: SCREEN_ID.LOGMANAGEMENT_LIST, //'B003-10'
            },
            // インフォメーション
            {
              columnInfo: items.item_11_2,
              icon: 'comment',
              screenId: SCREEN_ID.INFORMATION_LIST, //'B004-10'
            },
            // ユーザー
            {
              columnInfo: items.item_11_3,
              icon: 'user',
              screenId: SCREEN_ID.USER_LIST, //'B005-10'
            },
            // ライセンス
            {
              columnInfo: items.item_11_4,
              icon: 'info-circle',
              screenId: null,
              onClickCallback: () => {
                this.$router.push('system/license')
              },
            },
          ],
        },
      ]
      return this.navInfoRole(navInfo)
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    /**
     * ロゴURL.
     * @return {String}
     */
    brandUrl() {
      return require('@/assets/images/neuplanet_logo.png')
    },

    getServiceLoading() {
      return this.$store.getters['init/getServiceLoading']
    },

    //通知ボタン判定用
    existNotice() {
      let count = this.$store.getters['init/getNoticeCount']
      if (count > 0) {
        return true
      }
      return false
    },

    useNoticeByRole() {
      let screenName = this.$store.getters['init/getScreenName'](
        SCREEN_ID.NOTICE_MODAL
      )
      // メニュー非表示
      if (screenName) {
        return true
      }
      return false
    },
  },

  methods: {
    getNoticeCount,
    /**
     * ユーザーロールによりメニュー表示・非表示制御
     */
    navInfoRole(navInfo) {
      //console.log('USER_EROLE MENU VISIBILITY============================')
      navInfo.forEach((xGroup) => {
        //console.log('domain=' + xGroup.columnInfo.domainColumnName)
        // ex) マスタ xGroup.columnInfo.domainColumnName = 'menu_5'
        // console.log(
        //   'group menu_5=' + xGroup.columnInfo.label
        // )
        let groupVisibility = 0
        xGroup.items.forEach((xMenu) => {
          // console.log(
          //   'item=' +
          //     xMenu.columnInfo.domainColumnName +
          //     '/screenId=' +
          //     xMenu.screenId +
          //     '/visibility=' +
          //     xMenu.columnInfo.visibility
          // )

          if (xMenu.screenId != null && xMenu.columnInfo.visibility != 0) {
            let screenName = this.$store.getters['init/getScreenName'](
              xMenu.screenId
            )
            // メニュー非表示
            if (screenName == null) {
              xMenu.columnInfo.visibility = 0
              //console.log('visibility change =' + xMenu.columnInfo.visibility)
            }
          }
          if (
            xMenu.columnInfo.visibility === 1 ||
            xMenu.columnInfo.visibility === 2
          ) {
            groupVisibility = 1
          }
        })
        // 全て非表示の場合、Groupも表示しないように再設定
        xGroup.columnInfo.visibility = groupVisibility
      })

      return navInfo
    },

    displayNotice() {
      //自動更新
      //clearInterval(this.noticeInterval)
      openSubModal(this, 'notice-modal')
    },

    logout() {
      let ganttRequestId = sessionStorage.getItem('ganttRequestId')
      // 手動配車編集画面を閉じる際に排他制御クリア処理を行う。
      if (ganttRequestId) {
        ManualPlanningService.closeList(ganttRequestId)
          .then(
            () => {
              // 処理なし
            },
            (e) => {
              console.log(e.response.data)
            }
          )
          .finally(() => {
            sessionStorage.removeItem('ganttRequestId')
          })
      }

      this.$store.dispatch('init/setServiceLoading')
      AuthenticationService.logout()
        .then(
          () => {
            // 処理なし
          },
          () => {
            //console.log('errormsg=' + e.response.data.message)
            // result = e.response.status
          }
        )
        .finally(() => {
          this.$store.dispatch('init/setServiceUnLoading')
          //console.log('logout result=' + result)
          this.$store.dispatch('authentication/logout')
        })
    },

    setErrorMsg(msg) {
      this.errorMessage = msg
    },

    setWarnMsg(msg) {
      this.warnMessage = msg
    },

    setInfoMsg(msg) {
      this.infoMessage = msg
    },
    afterCloseSetNotice() {
      this.$store.dispatch('init/setNoticeCount', 0)
      //自動更新
      // // interval reset
      // //if (this.existNotice() == false) {
      //   this.intervalNoticeCheck()
      // }
    },
    // //自動更新
    // intervalNoticeCheck() {
    //   this.noticeInterval = setInterval(() => {
    //     getNoticeCount(this)
    //     this.intervalCount++
    //     console.log(
    //       'RefreshNoticeCheck(' + this.intervalCount + ')==================='
    //     )
    //     this.stopNoticeCheck()
    //   }, 3000)
    // },
    // stopNoticeCheck() {
    //   if (this.existNotice() == true) {
    //     console.log('stopNoticeCheck1===================')
    //     this.intervalCount = 0
    //     clearInterval(this.noticeInterval)
    //   }
    // },
  },
  beforeDestroy() {
    // //自動更新
    // console.log('stopNoticeCheck2===================')
    // this.$store.dispatch('init/setNoticeCount', 0)
    // clearInterval(this.noticeInterval)
  },
}
</script>

<style lang="scss" scoped>
.navbar-title {
  color: #777;
}

/**
 * ナビゲーションバーに適用するパディング
 */
.navbar-padding {
  @include mq(xsmall) {
    padding: 0 70vw 0 0;
  }
  @include mq(small) {
    padding: 0 4vw;
  }
  @include mq(medium) {
    padding: 0 10vw;
  }

  @include mq(large) {
    padding: 0 15vw;
  }
  @include mq(xlarge) {
    padding: 0 17vw 0 18vw;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
