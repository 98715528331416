<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="formData"
          :inputComponents="inputComponents"
        ></BaseTabGroup>
      </template>
      <!-- <template #bottom>
        <b-card no-body>
          <b-tabs card>
            <b-tab :title="detailInfo.tab2.label" class="tab">
              <div id="BaseView">
                <div>
                  <div class="row">
                    <BaseLabel :columnInfo="detailInfo.stowage"> </BaseLabel>
                    <div v-if="ctrlItems">
                      <BaseButton
                        v-for="(item, index) in ctrlItems[0]"
                        :key="index"
                        class="mr-2"
                        :variant="item.variant"
                        :icon="item.icon"
                        :columnInfo="item.columnInfo"
                        :disabled="item.disabled"
                        @click="item.onClickCallback"
                      />
                    </div>
                  </div>
                  <section data-class="work-list-table">
                    <b-table
                      data-class="base-table"
                      class="table table-bordered base-table"
                      select-mode="single"
                      bordered
                      hover
                      no-border-collapse
                      responsive
                      small
                      sticky-header
                      selectable
                      outlined
                      :fields="fields"
                      :items="stowageTableItems"
                      @row-selected="onRowStowageSelected"
                      v-bind="$attrs"
                      v-on="$listeners"
                    >
                      <template v-slot:cell(key1)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template v-slot:cell(zone_priority_order)="row">
                        <b-form-input
                          v-model="row.item.zone_priority_order"
                          size="sm"
                          type="number"
                          v-bind="$attrs"
                          v-on="$listeners"
                        />
                      </template>
                    </b-table>
                  </section>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </template> -->
    </DetailPageLayout>

    <!-- 部署マスタモダール-->
    <DepartmentModal
      id="department-modal"
      type="entry"
      selectMode="range"
      :useUnset="false"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="addDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 情報モーダル -->
    <InfoModal
      id="information-detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />

    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import InformationInfo from './InformationInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  clear,
  addDepartment,
  remove,
  goRegister,
  register,
} from './InformationDetail.js'

export default {
  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
  },

  data() {
    return {
      stowageTableItems: [],
      stowageSelectedItem: [],
      formData: new InformationInfo(),
      obj: this,
      registerMessage: null,
      oriData: new InformationInfo(),
      isDisabled: true,
      isStowagePlaceClick: false,
      departmentModalSearchCondition: {},
    }
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    informationId: {
      type: Number,
    },
    areaKind: {
      type: String,
    },
    screenKbn: {
      type: Number,
    },
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      let info = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.INFORMATION_DETAIL
      )('detail_ctrl_info')
      return info
    },
    detailInfo() {
      let info = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.INFORMATION_DETAIL
      )('detail_info')
      //info.area_name.visibility = this.isDisable
      return info
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(
        this.beforeScreenRouteInfo,
        this.SCREEN_ID.INFORMATION_DETAIL
      )
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },

    ctrlItems() {
      return [
        [
          // 部署追加ボタン
          {
            variant: 'success',
            icon: 'plus-circle',
            columnInfo: this.detailCtrlInfo.add,
            onClickCallback: () => {
              this.isStowagePlaceClick = true
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind = null
              openSubModal(this, 'department-modal')
            },
          },
          // 削除ボタン
          {
            variant: 'success',
            icon: 'minus-circle',
            columnInfo: this.detailCtrlInfo.delete,
            onClickCallback: () => {
              remove(this)
            },
          },
        ],
      ]
    },

    inputComponents() {
      let inputComponentArray = []
      inputComponentArray[0] = {
        // インフォメーション内容
        type: 'text',
        id: 'informationDetail',
        columnInfo: this.detailInfo.information_detail,
        rules: [this.INPUT_TYPE_CHECK.REQUIRED],
      }
      inputComponentArray[1] = {
        // 表示開始日時
        type: 'dateTime',
        dateId: 'displayBeginDate',
        timeId: 'displayBeginTime',
        dateColumnInfo: this.detailInfo.display_begin_date,
        timeColumnInfo: this.detailInfo.display_begin_time,
        dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
        timeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
      }
      inputComponentArray[2] = {
        // 表示終了日時
        type: 'dateTime',
        dateId: 'displayEndDate',
        timeId: 'displayEndTime',
        dateColumnInfo: this.detailInfo.display_end_date,
        timeColumnInfo: this.detailInfo.display_end_time,
        dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
        timeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
      }
      inputComponentArray[3] = {
        // 有効／無効
        type: 'select',
        id: 'useFlag',
        columnInfo: this.detailInfo.use_flag,
        options: getListOptions(this.MASTER_CODE.USE_FLAG),
        rules: [this.INPUT_TYPE_CHECK.REQUIRED],
        onChangeCallback: () => {},
      }
      inputComponentArray[4] = {
        // 開示レベル
        type: 'select',
        id: 'informationReleaseLevel',
        columnInfo: this.detailInfo.information_release_level,
        options: getListOptions(this.MASTER_CODE.INFORMATION_RELEASE_LEVEL),
        rules: [this.INPUT_TYPE_CHECK.REQUIRED],
        onChangeCallback: () => {},
      }
      inputComponentArray[5] = {
        // 重要度
        type: 'text',
        id: 'importance',
        columnInfo: this.detailInfo.importance,
        rules: [
          this.INPUT_TYPE_CHECK.REQUIRED,
          this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
        ],
      }
      inputComponentArray[6] = {
        // 登録者区分
        type: 'select',
        id: 'entryUserKind',
        columnInfo: this.detailInfo.entry_user_kind,
        options: getListOptions(this.MASTER_CODE.ENTRY_USER_KIND),
        rules: [this.INPUT_TYPE_CHECK.REQUIRED],
        onChangeCallback: () => {},
      }
      inputComponentArray[7] = {
        // 備考
        type: 'text',
        id: 'note',
        columnInfo: this.detailInfo.note,
      }
      inputComponentArray[8] = {
        // 登録日時
        type: 'text',
        id: 'registrationTimeDisplay',
        columnInfo: this.detailInfo.registration_time_display,
      }
      inputComponentArray[9] = {
        // 登録ユーザ
        type: 'text',
        id: 'registrationUser',
        columnInfo: this.detailInfo.registration_user,
      }
      inputComponentArray[10] = {
        // 更新日時
        type: 'text',
        id: 'updateTimeDisplay',
        columnInfo: this.detailInfo.update_time_display,
      }
      inputComponentArray[11] = {
        // 更新ユーザ
        type: 'text',
        id: 'updateUser',
        columnInfo: this.detailInfo.update_user,
      }
      return [
        {
          // 詳細情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: inputComponentArray,
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.INFORMATION_DETAIL
      )(this.DOMAIN_NAME.FIELDS)
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
    isDisable() {
      if (this.screenKbn == this.DETAIL_SCREEN_KBN.DETAIL) {
        // 詳細
        return this.VISIBILITY.DISABLE
      } else {
        return this.VISIBILITY.VISIBLE
      }
    },
  },

  methods: {
    getListOptions,
    register,
    back,

    onRowStowageSelected(selectedItems) {
      this.stowageSelectedItem = selectedItems
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    addDepartment(id, code, name) {
      addDepartment(this, id, code, name)
    },
  },
}
</script>
