import { createAuthenticaionService } from '@/services/index'

const BASE_URL = `${process.env.VUE_APP_API_URL}dashboard/`

class DashboardService {
  // getAll() {
  //   return createAuthenticaionService(BASE_URL).get('/')
  // }

  get(id) {
    return createAuthenticaionService(BASE_URL).get(`/${id}`)
  }

  // search(searchCondition) {
  //   const param = makeQueryParams(searchCondition)
  //   return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)
  // }
}

export default new DashboardService()
