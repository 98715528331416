import { openMsgModal, openSubModal } from '@/common/Common.js'
import { MSG_TYPE } from '@/common/const.js'
import UserService from './UserService'

export async function goUpdate(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.updateMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.update.label
  )
  openSubModal(obj, 'update-modal')
}

export async function update(obj) {
  obj.$bvModal.hide('update-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.$store.dispatch('init/setServiceLoading')
  const loginUserId = obj.$store.getters['authentication/getUserId']
  UserService.changePassword(loginUserId, obj.passwordInfo)
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'password-change-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(this, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}
