import InformationService from './InformationService'
import {
  openMsgModal,
  openSubModal,
  getMasterName,
  displaYyyymmdd,
  displayHHmmss,
  formatYYYYMMDD,
  formatHHmmss,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import {
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  USE_FLAG,
  MASTER_CODE,
  INFORMATION_RELEASE_LEVEL,
  ENTRY_USER_KIND,
} from '@/common/const.js'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    InformationService.get(obj.informationId)
      .then(
        (response) => {
          obj.formData = response.data
          obj.formData.displayBeginDate = displaYyyymmdd(
            obj,
            obj.formData.displayBeginDate
          )
          obj.formData.displayBeginTime = displayHHmmss(
            obj.formData.displayBeginTime
          )

          obj.formData.displayEndDate = displaYyyymmdd(
            obj,
            obj.formData.displayEndDate
          )
          obj.formData.displayEndTime = displayHHmmss(
            obj.formData.displayEndTime
          )

          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.formData.informationId = null
            obj.formData.registrationTimeDisplay = null
            obj.formData.registrationUser = null
            obj.formData.updateTimeDisplay = null
            obj.formData.updateUser = null
            obj.formData.informationDetails.forEach((element) => {
              element.informationId = null
            })
          }

          // details
          var details = convertSnakeCace(obj.formData.informationDetails)

          obj.formData.informationDetails = details
          // コピー
          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.formData.informationDetails.map(function (x) {
              x.informationId = ''
              return x
            })
          }
          obj.formData.informationDetails.forEach((element) => {
            obj.stowageTableItems.push(element)
          })

          obj.oriData = _.cloneDeep(obj.formData)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  } else {
    obj.formData.displayBeginTime = '00:00:00'
    obj.formData.displayEndTime = '23:59:59'
    obj.formData.useFlag = USE_FLAG.VALID
    obj.formData.useFlagName = getMasterName(
      MASTER_CODE.USE_FLAG,
      USE_FLAG.VALID
    )

    obj.formData.informationReleaseLevel = INFORMATION_RELEASE_LEVEL.ALL
    obj.formData.informationReleaseLevelName = getMasterName(
      MASTER_CODE.INFORMATION_RELEASE_LEVEL,
      INFORMATION_RELEASE_LEVEL.ALL
    )
    obj.formData.entryUserKind = ENTRY_USER_KIND.USER
    obj.formData.entryUserKindName = getMasterName(
      MASTER_CODE.ENTRY_USER_KIND,
      ENTRY_USER_KIND.USER
    )

    obj.formData.importance = 0
    obj.formData.informationDetails = []

    obj.oriData = _.cloneDeep(obj.formData)
  }
}

export function clear(obj) {
  obj.formData = _.cloneDeep(obj.oriData)
  obj.stowageTableItems = []

  if (obj.formData.informationDetails != null) {
    obj.formData.informationDetails.forEach((element) => {
      obj.stowageTableItems.push(element)
    })
  }
  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

export function addDepartment(obj, sectionId, sectionCode, sectionName) {
  let tableItems
  tableItems = obj.stowageTableItems

  let info = {
    // 部署ＩＤ
    section_id: sectionId,
    // 部署名
    section_name: sectionName,
  }

  let exists = false
  tableItems.forEach((item) => {
    if (item.section_id == sectionId) {
      exists = true
      return true
    }
  })

  if (exists == false) {
    tableItems.push(info)
    obj.isDisabled = false
  }
}

export function remove(obj) {
  let selectedItem
  let tableItems

  selectedItem = obj.stowageSelectedItem
  tableItems = obj.stowageTableItems

  // 一覧の選択行が存在しない場合、エラーを出力し、以降の処理は行わない。
  if (selectedItem.length == 0) {
    // 一覧より行を選択してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
    return
  }

  // 選択行を一覧より、削除する。
  selectedItem.forEach((element) => {
    const index = tableItems.indexOf(element)
    tableItems.splice(index, 1)
  })
  obj.isDisabled = false
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.register.label
  )
  openSubModal(obj, 'register-modal')
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  // 表示開始日・表示終了日の整合性チェック
  if ((await checkLogical(obj)) == false) {
    // 期間日付の入力に不整合があります。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG992E')
    )

    return
  }

  obj.formData.displayBeginDate = formatYYYYMMDD(
    obj,
    obj.formData.displayBeginDate
  )
  obj.formData.displayBeginTime = formatHHmmss(obj.formData.displayBeginTime)

  obj.formData.displayEndDate = formatYYYYMMDD(obj, obj.formData.displayEndDate)
  obj.formData.displayEndTime = formatHHmmss(obj.formData.displayEndTime)

  obj.formData.informationDetails = []
  obj.stowageTableItems.forEach((element) => {
    obj.formData.informationDetails.push(element)
  })

  // details
  var details = convertCamelCace(obj.formData.informationDetails)
  obj.formData.informationDetails = details

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = InformationService.create(obj.formData)
  } else {
    // 詳細
    callApi = InformationService.update(obj.informationId, obj.formData)
  }

  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'information-detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

function checkLogical(obj) {
  let result = true

  let displayBeginDateTime =
    formatYYYYMMDD(obj, obj.formData.displayBeginDate) +
    ' ' +
    formatHHmmss(obj.formData.displayBeginTime)

  let displayEndDateTime =
    formatYYYYMMDD(obj, obj.formData.displayEndDate) +
    ' ' +
    formatHHmmss(obj.formData.displayEndTime)

  // 予定開始日・予定終了日の整合性チェック
  if (obj.formData != null) {
    let startDate = obj
      .$moment(displayBeginDateTime)
      .format('YYYY-MM-DD HH:mm:ss')
    let endDate = obj.$moment(displayEndDateTime).format('YYYY-MM-DD HH:mm:ss')

    // 時刻チェック
    if (
      obj.$moment(startDate).isSame(obj.$moment(endDate)) == false &&
      obj.$moment(startDate).isBefore(obj.$moment(endDate)) == false
    ) {
      // ①同一レコードの開始日と終了日チェック異常
      result = false
    }
  }
  return result
}
