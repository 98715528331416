<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #side>
        <div class="tab-group">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="formData"
            :inputComponents="inputComponents"
          ></BaseTabGroup>
        </div>
      </template>
      <template #main>
        <div class="data-group-role">
          <BaseSelectField
            v-model="selectedDataGroup"
            :columnInfo="dataGroupRoleInfo.data_group"
            :options="blocks"
          />
          <BaseSelectField
            v-model="selectedRole"
            :columnInfo="dataGroupRoleInfo.role"
            :options="roles"
          />
          <div class="datagroup-role-controll">
            <BaseCheckbox
              v-show="false"
              v-model="includeDescendants"
              :columnInfo="dataGroupRoleInfo.include_descendants"
              :displayLabel="true"
            />
            <div>
              <BaseButton
                class="arrow-button"
                variant="success"
                :columnInfo="dataGroupRoleInfo.add"
                icon="arrow-down"
                @click="add(obj)"
              />
              <BaseButton
                class="arrow-button"
                variant="success"
                :disabled="!selectedItem"
                :columnInfo="dataGroupRoleInfo.remove"
                icon="arrow-up"
                @click="remove(obj)"
              />
            </div>
            <BaseButton
              variant="success"
              :disabled="!selectedItem"
              :columnInfo="dataGroupRoleInfo.update_role"
              @click="updateRole(obj)"
            />
          </div>
          <b-form-radio-group v-model="formData.defaultBlockCode">
            <b-table
              class="table table-bordered detail-table"
              bordered
              hover
              no-border-collapse
              responsive
              selectable
              small
              sticky-header
              outlined
              selectMode="single"
              :items="dataGroupRoleItems"
              :fields="dataGroupRoleFieldInfo"
              @row-selected="onRowSelected"
            >
              <template v-slot:cell(default)="row">
                <b-form-radio
                  class="td-radio"
                  :id="row.item.data_group"
                  :value="row.item.data_group"
                ></b-form-radio>
              </template>
            </b-table>
          </b-form-radio-group>
        </div>
      </template>
    </DetailPageLayout>
    <!-- 情報モーダル -->
    <InfoModal
      id="user-detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 部署参照モーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      selectMode="single"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- パスワード初期化モーダル -->
    <ConfirmModal
      id="init-password-modal"
      :columnInfos="messageInfo"
      :message="initPasswordMessage"
      @confirm="initPassword(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import UserInfo from './UserInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
//import { DEPARTMENT_KIND } from '@/common/const.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  goInitPassword,
  initPassword,
  goRegister,
  register,
  clear,
  add,
  remove,
  updateRole,
} from './UserDetail.js'

export default {
  name: 'userDetail',

  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    userId: {
      type: String,
      default: '',
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      formData: new UserInfo(),
      obj: this,
      selectedDataGroup: null,
      selectedRole: null,
      includeDescendants: false,
      dataGroupRoleItems: [],
      selectedItem: null,
      initPasswordMessage: null,
      registerMessage: null,
      oriData: new UserInfo(),
      oriDataGroupRoleItems: [],
      departmentModalSearchCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    defaultBlockCode() {
      return this.formData.defaultBlockCode
    },

    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.USER_DETAIL)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      let detailInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.USER_DETAIL
      )('detail_info')

      if (this.screenKbn == this.DETAIL_SCREEN_KBN.DETAIL) {
        detailInfo.user_id.visibility = this.VISIBILITY.DISABLE
      }

      return detailInfo
    },
    dataGroupRoleInfo() {
      let dataGroupRoleInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.USER_DETAIL
      )('data_group_role')

      dataGroupRoleInfo.add.label = ''
      dataGroupRoleInfo.remove.label = ''

      return dataGroupRoleInfo
    },
    dataGroupRoleFieldInfo() {
      return this.$store.getters['init/getFields'](this.SCREEN_ID.USER_DETAIL)(
        'data_group_role_field'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(
        this.beforeScreenRouteInfo,
        this.SCREEN_ID.USER_DETAIL
      )
    },
    blocks() {
      return getListOptions(this.MASTER_CODE.BLOCK).sort(function (a, b) {
        if (!a.value) return -1
        if (!b.value) return 1
        let valueA = a.value.toUpperCase()
        let valueB = b.value.toUpperCase()
        if (valueA < valueB) {
          return -1
        }
        if (valueA > valueB) {
          return 1
        }
        return 0
      })
    },
    roles() {
      return getListOptions(this.MASTER_CODE.ROLE).sort(function (a, b) {
        if (!a.value) return -1
        if (!b.value) return 1
        let valueA = a.value.toUpperCase()
        let valueB = b.value.toUpperCase()
        if (valueA < valueB) {
          return -1
        }
        if (valueA > valueB) {
          return 1
        }
        return 0
      })
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // パスワード初期化ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.init_password,
            onClickCallback: () => {
              goInitPassword(this)
            },
          },
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
      ]
    },
    inputComponents() {
      return [
        {
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // ユーザーID
              type: 'text',
              id: 'userId',
              columnInfo: this.detailInfo.user_id,
              maxLength: 50,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
            },
            {
              // ユーザー名
              type: 'text',
              id: 'userName',
              maxLength: 20,
              columnInfo: this.detailInfo.user_name,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // ユーザー属性
              type: 'select',
              id: 'userType',
              columnInfo: this.detailInfo.user_type,
              options: getListOptions(this.MASTER_CODE.USER_TYPE),
              onChangeCallback: () => {},
            },
            {
              // メールアドレス
              type: 'text',
              id: 'email',
              maxLength: 256,
              columnInfo: this.detailInfo.email,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.EMAIL,
              ],
            },
            {
              // 所属部署
              type: 'referenceCodeName',
              codeId: 'belongDepartmentCode',
              nameId: 'belongDepartmentName',
              codeColumnInfo: this.detailInfo.belong_department_code,
              nameColumnInfo: this.detailInfo.belong_department_name,
              btnColumnInfo: this.detailInfo.sub_modal_belong_department,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.departmentModalSearchCondition = {}
                this.departmentModalSearchCondition.departmentKind = null
                this.openSubModal(this, 'department-modal')
              },
            },
            {
              // 言語
              type: 'select',
              id: 'defaultLang',
              columnInfo: this.detailInfo.default_lang,
              options: getListOptions(this.MASTER_CODE.LANGUAGE),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // ユーザーロック
              type: 'checkbox',
              id: 'userLocked',
              columnInfo: this.detailInfo.user_lock,
            },
          ],
        },
        {
          // 予備タブ
          label: this.detailInfo.tab2.label,
          inputComponents: [
            {
              // 予備１
              type: 'text',
              id: 'reserve01',
              columnInfo: this.detailInfo.reserve01,
            },
            {
              // 予備２
              type: 'text',
              id: 'reserve02',
              columnInfo: this.detailInfo.reserve02,
            },
            {
              // 予備３
              type: 'text',
              id: 'reserve03',
              columnInfo: this.detailInfo.reserve03,
            },
            {
              // 予備４
              type: 'text',
              id: 'reserve04',
              columnInfo: this.detailInfo.reserve04,
            },
            {
              // 予備５
              type: 'text',
              id: 'reserve05',
              columnInfo: this.detailInfo.reserve05,
            },
            {
              // 予備６
              type: 'text',
              id: 'reserve06',
              columnInfo: this.detailInfo.reserve06,
            },
            {
              // 予備７
              type: 'text',
              id: 'reserve07',
              columnInfo: this.detailInfo.reserve07,
            },
            {
              // 予備８
              type: 'text',
              id: 'reserve08',
              columnInfo: this.detailInfo.reserve08,
            },
            {
              // 予備９
              type: 'text',
              id: 'reserve09',
              columnInfo: this.detailInfo.reserve09,
            },
            {
              // 予備１０
              type: 'text',
              id: 'reserve10',
              columnInfo: this.detailInfo.reserve10,
            },
          ],
        },
      ]
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    initPassword,
    back,
    add,
    remove,
    updateRole,

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      this.formData.belongDepartmentId = id
      this.formData.belongDepartmentCode = code
      this.formData.belongDepartmentName = name
    },

    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.formData.belongDepartmentId = null
      this.formData.belongDepartmentCode = null
      this.formData.belongDepartmentName = null
    },

    onRowSelected(selectedItems) {
      if (selectedItems.length > 0) {
        this.selectedItem = selectedItems[0]
        this.selectedDataGroup = this.selectedItem.data_group
        this.selectedRole = this.selectedItem.role
      } else {
        this.selectedItem = null
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-group {
  margin-bottom: 12px;
}

.data-group-role {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 14px;
}

.datagroup-role-controll {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 8px 12px;
}
.arrow-button {
  width: 30px;
  margin-right: 8px;
}

.td-radio {
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}

.detail-table {
  font-size: 0.8rem;
  th,
  td {
    padding: 5px;
    vertical-align: middle;
  }

  th {
    background-color: $primary;
    color: #eee;
    padding: 0 1rem;
    text-align: center;
    width: 130px;
  }
}
</style>
