<template>
  <div data-class="login-layout">
    <b-jumbotron class="login-jumbotron">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-7">
            <div data-class="logo" class="logo" />
            <h2 data-class="system-title" class="system-title">
              {{ screenInfoDetailInfo.sysname.label }}
            </h2>
            <div style="border: solid 1px #000">
              <BaseTextArea v-model="text" :columnInfo="columnInfo" :rows="6" />
            </div>
          </div>
          <div class="col-sm-12 col-md-5">
            <b-card
              data-class="login-form"
              :header="screenInfoDetailInfo.title.label"
              :class="getAzureClass"
            >
              <div v-if="userAzureAD" class="row">
                <div class="col-xs-12 col-sm-5">
                  <!-- AzureAD ログインボタン -->
                  <BaseButton
                    block
                    class="mt-3 mb-3"
                    variant="success"
                    icon="sign-in-alt"
                    :columnInfo="screenInfoDetailInfo.login"
                    @click="azurelogin"
                  />
                </div>
              </div>
              <div v-else>
                <ValidationObserver ref="login-form">
                  <!-- ユーザID -->
                  <BaseTextInputField
                    v-model="loginRequest.userId"
                    :columnInfo="screenInfoDetailInfo.user_id"
                    :rules="[
                      INPUT_TYPE_CHECK.REQUIRED,
                      INPUT_TYPE_CHECK.MAXLENGTH,
                      INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
                    ]"
                    maxLength="50"
                  />

                  <!-- パスワード -->
                  <BaseTextInputField
                    v-model="loginRequest.password"
                    :columnInfo="screenInfoDetailInfo.password"
                    type="password"
                    :rules="[
                      INPUT_TYPE_CHECK.REQUIRED,
                      INPUT_TYPE_CHECK.MAXLENGTH,
                      INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
                    ]"
                    @keyup.enter="login"
                    maxLength="120"
                  />
                </ValidationObserver>
                <div class="row">
                  <div class="col-xs-12 col-sm-5">
                    <!-- ログインボタン -->
                    <BaseButton
                      block
                      variant="success"
                      icon="sign-in-alt"
                      :columnInfo="screenInfoDetailInfo.login"
                      :disabled="!canLogin"
                      @click="login"
                    />
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col">
                  <!-- 言語 -->
                  <BaseSelectField
                    v-model="selectedLanguage"
                    :columnInfo="screenInfoDetailInfo.language"
                    :options="languages"
                    @change="changeLanguage"
                    class="mb-4"
                  />
                </div>
              </div>

              <!-- エラーモーダル -->
              <ErrorModal
                id="error-modal"
                :columnInfos="messageInfo"
                :message="errorMessage"
              />
            </b-card>
          </div>
        </div>
      </div>
    </b-jumbotron>
  </div>
</template>

<script>
import { initMessge } from '@/plugins/validate.js'
import LoginRequest from './loginRequest'
import AzureAdAuthenticationService from '@/system/authentication/AzureAdAuthenticationService'
import { SCREEN_ID } from '@/common/const.js'

export default {
  name: 'LoginPage',

  data() {
    return {
      text: '',
      columnInfo: {
        visible() {
          return true
        },
        disable() {
          return true
        },
      },
      loginRequest: new LoginRequest('', ''),
      errorMessage: null,
      selectedLanguage: 'ja',
    }
  },

  created() {
    this.changeLanguage(this.selectedLanguage)
    this.text = this.$store.getters['init/getSystemManagement']('A001')(
      'Message'
    )
  },

  computed: {
    userAzureAD() {
      return AzureAdAuthenticationService.clientId()
    },
    getAzureClass() {
      let newClass = 'mt-1'
      if (this.userAzureAD != null) {
        newClass = 'mt-5'
      }
      return newClass
    },
    /**
     * メッセージ情報.
     *
     * @return {Object} 選択した言語用のメッセージ情報を返します。
     */
    messageInfo() {
      return this.$store.getters['init/getScreenInfoForLogin'](
        this.selectedLanguage
      )(this.SCREEN_ID.COMMON)('message')
    },
    /**
     * 言語情報リスト.
     *
     * @return {Array} 言語情報リストを返します。
     */
    languages() {
      return this.$store.getters['init/getLanguages'](this.selectedLanguage)
    },
    /**
     * ログイン可能か.
     *
     * @return {Boolean} ログイン可否を返します。
     */
    canLogin() {
      return (
        this.loginRequest.userId &&
        this.loginRequest.password &&
        this.loginRequest.userId.trim() != '' &&
        this.loginRequest.password.trim() != ''
      )
    },
    /**
     * 画面表示情報.
     *
     * @return {Object} 選択した言語用の画面表示情報を返します。
     */
    screenInfoDetailInfo() {
      return this.$store.getters['init/getScreenInfoForLogin'](
        this.selectedLanguage
      )(this.SCREEN_ID.LOGIN)('detail_info')
    },
  },

  methods: {
    /**
     * ログイン要求処理を行います。
     *
     * 入力チェックを行い、サーバーへログインを要求します。
     * ログインに成功した場合、ログイン言語用の画面表示情報を取得してから、初期画面に遷移します。
     */
    async login() {
      const validated = await this.$refs['login-form'].validate()
      if (!validated) {
        return
      }

      await this.$store
        .dispatch('authentication/login', this.loginRequest)
        .then(() => {
          return this.$store.dispatch(
            'init/loadInitInfo',
            this.selectedLanguage
          )
        })
        .then((initInfo) => {
          console.log(initInfo)
          this.$router.push({ name: SCREEN_ID.DASHBOARD })
        })
        .catch((e) => {
          let message
          if (e.response == undefined) {
            message = this.$store.getters['init/getMessage']('YZ00MG946E')
          } else {
            message = e.response.data.message
          }
          this.openErrorModal(message)
        })
    },

    async azurelogin() {
      try {
        await AzureAdAuthenticationService.login()
        this.$emit('loginComplete')

        console.log(
          'AzureAdAuthenticationService.user()',
          AzureAdAuthenticationService.user()
        )

        this.loginRequest.userId = AzureAdAuthenticationService.user().userName
        this.loginRequest.password = 'DUMMY'

        await this.$store
          .dispatch('authentication/login', this.loginRequest)
          .then(() => {
            return this.$store.dispatch(
              'init/loadInitInfo',
              this.selectedLanguage
            )
          })
          .then((initInfo) => {
            console.log(initInfo)
            if (this.redir) {
              this.$router.push({ name: this.redir })
            } else {
              this.$router.push({ name: SCREEN_ID.DASHBOARD }).catch(() => {})
            }
          })
          .catch((e) => {
            let message
            if (e.response == undefined) {
              message = this.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              message = e.response.data.message
            }
            this.openErrorModal(message)
          })
      } catch (err) {
        console.log('### MSAL Error ' + err.toString())
        this.openErrorModal(err.toString())
      }
    },

    /**
     * ログイン言語変更時の処理を行います。
     *
     * 選択した言語用のメッセージ情報を初期化し、画面もリセットします。
     *
     * @param {string} language 言語
     */
    async changeLanguage(language) {
      this.loginRequest.language = language

      this.$store.dispatch('init/setMessageInfoByLanguage', language)
      initMessge(this.$store)

      if (this.$refs['login-form'] != undefined) {
        this.$refs['login-form'].reset()
        // this.$refs['login-form'].validate()
      }
    },

    /**
     * 作業データエラーモーダルを開きます.
     *
     * @param {string} message メッセージ
     */
    openErrorModal(message) {
      this.errorMessage = message
      this.$bvModal.show('error-modal')
    },
  },
}
</script>

<style lang="scss" scoped>
.login-jumbotron {
  @include mq(xsmall) {
    margin: 0;
    min-height: 100vh;
    width: 100vw;
  }

  @include mq {
    margin: 40px auto;
    max-width: 1000px;
    min-height: 460px;
    width: 90vw;
  }
}

.logo {
  background: url('~@/assets/images/neuplanet_full.png') no-repeat left center;
  height: 96px;
  width: 327px;
}

.system-title {
  font-size: 1.8em;

  @include mq(xsmall) {
    text-align: center;
  }

  @include mq() {
    text-align: left;
  }
}
</style>
