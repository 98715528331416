import {
  openMsgModal,
  openSubModal,
  downloadCSV,
  csvFileName,
} from '@/common/Common.js'
import { SCREEN_ID, MSG_TYPE, DETAIL_SCREEN_KBN } from '@/common/const.js'
import router from '@/router'
import UserService from './UserService'

const screenId = SCREEN_ID.USER_LIST
const detailScreenId = SCREEN_ID.USER_DETAIL

export function init(obj) {
  // サーバーの処理後再検索
  if (obj.isReSearch == true) {
    reSearch(obj)
  }
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

/**
 * ユーザを検索します.
 * @param {*} obj
 */
export function search(obj) {
  obj.$store.dispatch('init/setServiceLoading')
  UserService.search(obj.searchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList
        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()
  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

// 「詳細」ボタンクリック
export function goDetail(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        userId: obj.selectedItem[0].user_id,
        screenKbn: DETAIL_SCREEN_KBN.DETAIL,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 「新規」ボタンクリック
export function goNew() {
  router.push({
    name: detailScreenId,
    params: {
      screenKbn: DETAIL_SCREEN_KBN.NEW,
      beforeScreenRouteInfo: {
        routeInfo: {
          name: screenId,
          params: { isReSearch: true },
        },
      },
    },
  })
}

// 「削除」ボタンクリック
export function goRemove(obj) {
  // ログインユーザは削除できない
  const loginUserId = obj.$store.getters['authentication/getUserId']
  for (let index = 0; index < obj.selectedItem.length; index++) {
    var userId = obj.selectedItem[index].user_id
    if (userId === loginUserId) {
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('YZ00MG937E')
      )
      return
    }
  }

  // 処理確認メッセージ
  obj.removeMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.delete.label
  )
  openSubModal(obj, 'remove-modal')
}

/**
 * 選択した作業データを削除します.
 */
export async function remove(obj) {
  try {
    obj.$bvModal.hide('remove-modal')

    let isError = false
    let removeList = []
    for (let index = 0; index < obj.selectedItem.length; index++) {
      var userId = obj.selectedItem[index].user_id
      obj.$store.dispatch('init/setServiceLoading')
      await UserService.get(userId)
        .then(
          (response) => {
            removeList.push(response.data)
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
            isError = true
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
      if (isError) {
        break
      }
    }
    if (isError == false && removeList.length == obj.selectedItem.length) {
      obj.$store.dispatch('init/setServiceLoading')
      await UserService.removeList(removeList)
        .then(
          () => {
            // 処理完了メッセージ表示
            openMsgModal(
              obj,
              MSG_TYPE.I,
              obj.$store.getters['init/getMessage'](
                'YZ00MG995I',
                obj.resultControlInfo.delete.label
              )
            )
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
    }
  } catch (err) {
    console.error(err)
  } finally {
    var searchCondition = obj.$store.getters['search/getSearchCondition'](
      screenId
    )
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

// 「CSV出力」ボタンクリック
export function download(obj) {
  obj.$bvModal.hide('download-modal')

  //obj.searchCondition.filename = 'SA01F001.csv'
  let newCsvName = csvFileName(obj, screenId)
  obj.searchCondition.filename = newCsvName

  obj.$store.dispatch('init/setServiceLoading')
  UserService.download(obj.searchCondition)
    .then(
      (response) => {
        if (response.data == '') {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        } else {
          downloadCSV(obj.searchCondition.filename, response.data)
        }
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, obj.MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}
