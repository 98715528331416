import { openMsgModal } from '@/common/Common.js'
import { MSG_TYPE } from '@/common/const.js'
// import router from '@/router'
import DashboardService from './DashboardService'
import _ from 'lodash'

export function init(obj) {
  obj.$store.dispatch('init/setServiceLoading')
  DashboardService.get(1)
    .then(
      (response) => {
        obj.formData = response.data
        // 件数
        let value1 = obj.formData.needUpdateOrderCount
        let value2 = obj.formData.updateOrDeleteOrderCount
        let value3 = obj.formData.orderPayApproveReturnCount
        let value4 = obj.formData.requestPayApproveReturnCount
        let value5 = obj.formData.orderRejectCount

        let countFormat1 = obj.detailInfo.need_update_order_count.label
        let countFormat2 = obj.detailInfo.update_or_delete_order_count.label
        let countFormat3 = obj.detailInfo.order_pay_approve_return_count.label
        let countFormat4 = obj.detailInfo.request_pay_approve_return_count.label
        let countFormat5 = obj.detailInfo.order_reject_count.label

        // 要修正受注件数：%s件
        // 変更・削除オーダー件数：%s件
        // 発注承認差し戻し件数：%s件
        // 請求支払承認差し戻し件数：%s件
        // 発注辞退件数：%s件
        obj.count1 = countFormat1.replace('%s', formatCountValue(value1))
        obj.count2 = countFormat2.replace('%s', formatCountValue(value2))
        obj.count3 = countFormat3.replace('%s', formatCountValue(value3))
        obj.count4 = countFormat4.replace('%s', formatCountValue(value4))
        obj.count5 = countFormat5.replace('%s', formatCountValue(value5))

        // 集計実行日時
        obj.executeDateTime =
          obj.detailInfo.execute_date_time.label + obj.formData.executeDateTime

        // バー色リスト取得
        let colorList = mulitBarColor(obj)
        // チャットデータ設定
        obj.chartDatas1 = chartDatas(obj, 10, colorList)
        obj.chartDatas2 = chartDatas(obj, 11, colorList)
        obj.chartDatas3 = chartDatas(obj, 12, colorList)
        obj.chartDatas4 = chartDatas(obj, 13, colorList)
        obj.chartDatas5 = chartDatas(obj, 20, colorList)
        obj.chartDatas6 = chartDatas(obj, 21, colorList)
        obj.chartDatas7 = chartDatas(obj, 30, colorList)
        obj.chartDatas8 = chartDatas(obj, 31, colorList)
        obj.chartDatas9 = chartDatas(obj, 32, colorList)
        obj.chartDatas10 = chartDatas(obj, 40, colorList)
        obj.chartDatas11 = chartDatas(obj, 50, colorList)
        obj.chartDatas12 = chartDatas(obj, 51, colorList)
        // チャット属性設定
        obj.chartOptions1 = chartOptions(obj, 10)
        obj.chartOptions2 = chartOptions(obj, 11)
        obj.chartOptions3 = chartOptions(obj, 12)
        obj.chartOptions4 = chartOptions(obj, 13)
        obj.chartOptions5 = chartOptions(obj, 20)
        obj.chartOptions6 = chartOptions(obj, 21)
        obj.chartOptions7 = chartOptions(obj, 30)
        obj.chartOptions8 = chartOptions(obj, 31)
        obj.chartOptions9 = chartOptions(obj, 32)
        obj.chartOptions10 = chartOptions(obj, 40)
        obj.chartOptions11 = chartOptions(obj, 50)
        obj.chartOptions12 = chartOptions(obj, 51)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 数字表示変換
 * @param {*} value
 */
function formatCountValue(value) {
  // 123456->123,456
  if (typeof value !== 'number') {
    return value
  }
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

/**
 * ヂャットdatasetsの新しい項目情報を作成する
 * @param {*} unit 単位
 * @param {*} datas データ値
 * @param {*} barColor バー色
 */
function makeElement(unit, datas, barColor) {
  // 例）
  // {
  //   label: 'Data One',
  //   backgroundColor: '#f87979',
  //   pointBackgroundColor: 'white',
  //   borderWidth: 1,
  //   pointBorderColor: '#249EBF',
  //   //Data to be represented on y-axis
  //   data: [40, 20, 30, 50, 90, 10, 20, 40, 50, 70, 90, 100],
  // },
  let newElement = {
    label: unit,
    pointBackgroundColor: 'white',
    borderWidth: 1,
    pointBorderColor: '#249EBF',
    backgroundColor: barColor,
    data: datas,
  }
  return newElement
}

/**
 * ヂャットdatasetsの新しい項目情報(複数)を作成する
 * @param {*} datacollection ヂャットのデータ部情報
 * @param {*} labels ヂャットのx軸
 * @param {*} dataset ヂャットのデータ項目情報（値、単位など）
 * @param {*} barColors ヂャットのバー色
 */
function chartDatasElement(datacollection, labels, dataset, barColors) {
  datacollection.labels = labels
  let idx = 0
  if (dataset == null) {
    let info = makeElement(null, null, null)
    datacollection.datasets.splice(idx, 1, info)
    return datacollection
  }
  for (let datas of dataset) {
    let barColor = getBarColor(barColors, idx)
    let info = makeElement(datas.label, datas.statusValues, barColor)
    // console.log(
    //   'idx=' + idx + ', label=' + info.label + ', barColor=' + barColor
    // )
    // [] add
    datacollection.datasets.splice(idx, 1, info)
    idx++
  }
  return datacollection
}

/**
 * チャットdatasets情報設定（軸ラベル、軸データ、単位、バー色、）
 * @param {*} obj
 * @param {*} kbn
 * @param {*} barColors
 */
function chartDatas(obj, kbn, barColors) {
  let kbnChartDatas = _.cloneDeep(obj.datacollection)
  let idx = 0
  let newElement
  let barColor01 = getBarColor(barColors, idx)
  switch (kbn) {
    case 10: // 10：新規受注件数
      kbnChartDatas.labels = obj.formData.newReceivedOrderLabels
      newElement = makeElement(
        obj.detailInfo.chart10_unit.label,
        obj.formData.newReceivedOrderValues,
        barColor01
      )
      kbnChartDatas.datasets.splice(idx, 1, newElement)
      break
    case 11: // 11：輸送オーダー生成件数
      kbnChartDatas.labels = obj.formData.newShippingOrderLabels
      newElement = makeElement(
        obj.detailInfo.chart11_unit.label,
        obj.formData.newShippingOrderValues,
        barColor01
      )
      kbnChartDatas.datasets.splice(idx, 1, newElement)
      break
    case 12: // 12：要修正受注オーダー件数
      kbnChartDatas.labels = obj.formData.correctionRequiredOrderLabels
      newElement = makeElement(
        obj.detailInfo.chart12_unit.label,
        obj.formData.correctionRequiredOrderValues,
        barColor01
      )
      kbnChartDatas.datasets.splice(idx, 1, newElement)
      break
    case 13: // 13：変更削除オーダー件数
      kbnChartDatas.labels = obj.formData.changedOrderLabels
      newElement = makeElement(
        obj.detailInfo.chart13_unit.label,
        obj.formData.changedOrderValues,
        barColor01
      )
      kbnChartDatas.datasets.splice(idx, 1, newElement)
      break
    case 20: // 20：顧客ごとの配車情報
      kbnChartDatas = chartDatasElement(
        kbnChartDatas,
        obj.formData.customerPlanningOrderLabels,
        obj.formData.customerPlanningOrderDataSet,
        barColors
      )
      break
    case 21: // 21：未割付件数
      kbnChartDatas.labels = obj.formData.nonAllocatedLabels
      newElement = makeElement(
        obj.detailInfo.chart21_unit.label,
        obj.formData.nonAllocatedValues,
        barColor01
      )
      kbnChartDatas.datasets.splice(idx, 1, newElement)
      break
    case 30: // 30：配車情報
      kbnChartDatas = chartDatasElement(
        kbnChartDatas,
        obj.formData.operationLabels,
        obj.formData.operationDataSet,
        barColors
      )
      break
    case 31: // 31：発注情報
      kbnChartDatas = chartDatasElement(
        kbnChartDatas,
        obj.formData.orderInfoLabels,
        obj.formData.orderInfoDataSet,
        barColors
      )
      break
    case 32: // 32：承諾情報
      kbnChartDatas = chartDatasElement(
        kbnChartDatas,
        obj.formData.approveOrderLabels,
        obj.formData.approveOrderDataSet,
        barColors
      )
      break
    case 40: // 40：輸送完了状況
      kbnChartDatas = chartDatasElement(
        kbnChartDatas,
        obj.formData.transportCompleteLabels,
        obj.formData.transportCompleteDataSet,
        barColors
      )
      break
    case 50: // 50：積込済み情報
      kbnChartDatas = chartDatasElement(
        kbnChartDatas,
        obj.formData.loadingFinishedLabels,
        obj.formData.loadingFinishedDataSet,
        barColors
      )
      break
    case 51: // 51：搬入済み情報
      kbnChartDatas = chartDatasElement(
        kbnChartDatas,
        obj.formData.carriedInLabels,
        obj.formData.carriedInDataSet,
        barColors
      )
      break
  }
  return kbnChartDatas
}

/**
 * バー色定義取得してリストを返す
 * @param {*} obj
 */
function mulitBarColor(obj) {
  let colorList = []
  colorList.push(obj.detailInfo.multi_barcolor_01.label)
  colorList.push(obj.detailInfo.multi_barcolor_02.label)
  colorList.push(obj.detailInfo.multi_barcolor_03.label)
  colorList.push(obj.detailInfo.multi_barcolor_04.label)
  colorList.push(obj.detailInfo.multi_barcolor_05.label)
  colorList.push(obj.detailInfo.multi_barcolor_06.label)
  colorList.push(obj.detailInfo.multi_barcolor_07.label)
  colorList.push(obj.detailInfo.multi_barcolor_08.label)
  colorList.push(obj.detailInfo.multi_barcolor_09.label)
  colorList.push(obj.detailInfo.multi_barcolor_10.label)
  return colorList
}

/**
 * バー色定義リストから該当色を取得する
 * @param {*} colorList
 * @param {*} idx
 */
function getBarColor(colorList, idx) {
  if (colorList != null) {
    //console.log('idx=' + idx + ', colorList.length=' + colorList.length)
    return colorList[idx % colorList.length]
  }
}

/**
 * チャットOptions情報設定（チャットタイトル、属性など）
 * @param {*} obj
 * @param {*} kbn
 */
function chartOptions(obj, kbn) {
  let kbnChartOptions = _.cloneDeep(obj.options)
  let chartTitle
  let isStacked = false

  switch (kbn) {
    case 10:
      chartTitle = obj.detailInfo.chart10_title.label
      break
    case 11:
      chartTitle = obj.detailInfo.chart11_title.label
      break
    case 12:
      chartTitle = obj.detailInfo.chart12_title.label
      break
    case 13:
      chartTitle = obj.detailInfo.chart13_title.label
      break
    case 20:
      chartTitle = obj.detailInfo.chart20_title.label
      if (obj.detailInfo.chart20_stacked.label == '1') {
        isStacked = true
      }
      break
    case 21:
      chartTitle = obj.detailInfo.chart21_title.label
      break
    case 30:
      chartTitle = obj.detailInfo.chart30_title.label
      if (obj.detailInfo.chart30_stacked.label == '1') {
        isStacked = true
      }
      break
    case 31:
      chartTitle = obj.detailInfo.chart31_title.label
      if (obj.detailInfo.chart31_stacked.label == '1') {
        isStacked = true
      }
      break
    case 32:
      chartTitle = obj.detailInfo.chart32_title.label
      if (obj.detailInfo.chart32_stacked.label == '1') {
        isStacked = true
      }
      break
    case 40:
      chartTitle = obj.detailInfo.chart40_title.label
      if (obj.detailInfo.chart40_stacked.label == '1') {
        isStacked = true
      }
      break
    case 50:
      chartTitle = obj.detailInfo.chart50_title.label
      if (obj.detailInfo.chart50_stacked.label == '1') {
        isStacked = true
      }
      break
    case 51:
      chartTitle = obj.detailInfo.chart51_title.label
      if (obj.detailInfo.chart51_stacked.label == '1') {
        isStacked = true
      }
      break
  }
  kbnChartOptions.title.text = chartTitle
  kbnChartOptions.scales.yAxes[0].stacked = isStacked
  kbnChartOptions.scales.xAxes[0].stacked = isStacked

  return kbnChartOptions
}
