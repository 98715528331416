/**
 * ユーザデータ.
 * @class
 * @exports
 */
export default class UserInfo {
  userId = ''
  userName = ''
  email = ''
  userType = ''
  userTypeName = ''
  belongDepartmentId = ''
  belongDepartmentCode = ''
  belongDepartmentName = ''
  defaultBlockCode = ''
  defaultBlockName = ''
  defaultLang = ''
  defaultLangName = ''
  userLocked = false
  deleted = false
  blockRoles = []
}
