<template>
  <div>
    <span></span>
    <!-- <p>配車、発注、請求支払の状況を集計し表示する</p> -->
    <div class="row">
      <div class="col-sm-12 col-md-8 col-lg-8">
        <ul class="item">
          <li class="item">
            <ul class="countGroup1">
              <li>
                <span>{{ count1 }}</span>
              </li>
              <li>
                <span>{{ count2 }}</span>
              </li>
              <li></li>
            </ul>
          </li>
          <li class="item">
            <ul class="countGroup1">
              <li>
                <span>{{ count3 }}</span>
              </li>
              <li>
                <span>{{ count4 }}</span>
              </li>
              <li>
                <span>{{ count5 }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="col-sm-12 col-md-4 col-lg--4">
        <ul class="datetime">
          <li>
            <span>{{ executeDateTime }}</span>
            &nbsp;
            <!-- 更新ボタン -->
            <BaseButton
              class="edit-button"
              variant="success"
              :columnInfo="detailCtrlInfo.refresh"
              icon="sync-alt"
              @click="goRefresh()"
            />
          </li>
        </ul>
      </div>
    </div>

    <!-- <BaseChartBar :chart-data="datacollection" :options="options" /> -->
    <ul class="item">
      <li class="item">
        <BaseChartBar :chart-data="chartDatas1" :options="chartOptions1" />
      </li>
      <li class="item">
        <BaseChartBar :chart-data="chartDatas2" :options="chartOptions2" />
      </li>
      <li class="item">
        <BaseChartBar :chart-data="chartDatas3" :options="chartOptions3" />
      </li>
      <li class="item">
        <BaseChartBar :chart-data="chartDatas4" :options="chartOptions4" />
      </li>
      <li class="item">
        <BaseChartBar :chart-data="chartDatas5" :options="chartOptions5" />
      </li>
      <li class="item">
        <BaseChartBar :chart-data="chartDatas6" :options="chartOptions6" />
      </li>
      <li class="item">
        <BaseChartBar :chart-data="chartDatas7" :options="chartOptions7" />
      </li>
      <li class="item">
        <BaseChartBar :chart-data="chartDatas8" :options="chartOptions8" />
      </li>
      <li class="item">
        <BaseChartBar :chart-data="chartDatas9" :options="chartOptions9" />
      </li>
      <li class="item">
        <BaseChartBar :chart-data="chartDatas10" :options="chartOptions10" />
      </li>
      <li class="item">
        <BaseChartBar :chart-data="chartDatas11" :options="chartOptions11" />
      </li>
      <li class="item">
        <BaseChartBar :chart-data="chartDatas12" :options="chartOptions12" />
      </li>
    </ul>
  </div>
</template>

<script>
import DashboardInfo from './DashboardInfo'
import { SCREEN_ID } from '@/common/const.js'
import { init } from '@/system/main/dashboard/Dashboard.js'
import { getNoticeCount } from '@/system/main/IndexPage.js'
import BaseChartBar from '../../../common/components/base/BaseChartBar.vue'

const screenId = SCREEN_ID.DASHBOARD

export default {
  name: 'dashboard',
  components: { BaseChartBar },
  props: {},
  data() {
    return {
      formData: new DashboardInfo(),
      obj: this,
      datacollection: {
        //Data to be represented on x-axis
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: '#249EBF',
            //Data to be represented on y-axis
            data: [40, 20, 30, 50, 90, 10, 20, 40, 50, 70, 90, 100],
          },
          // {
          //   label: 'Data Two',
          //   backgroundColor: '#a5a5a5',
          //   pointBackgroundColor: 'white',
          //   borderWidth: 1,
          //   pointBorderColor: '#249EBF',
          //   //Data to be represented on y-axis
          //   data: [45, 25, 35, 55, 95, 15, 25, 40, 50, 70, 90, 100],
          // },
        ],
      },
      //Chart.js options that controls the appearance of the chart
      options: {
        scales: {
          yAxes: [
            {
              stacked: false,
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              stacked: false,
              gridLines: {
                display: false,
              },
            },
          ],
        },
        title: {
          display: true,
          position: 'top',
          text: 'Graph',
        },
        legend: {
          display: false,
          position: 'bottom', //'top','left','bottom','right'
        },
        responsive: true,
        maintainAspectRatio: false, //default value. if false then div 0
      },
      chartDatas1: null,
      chartOptions1: null,
      chartDatas2: null,
      chartOptions2: null,
      chartDatas3: null,
      chartOptions3: null,
      chartDatas4: null,
      chartOptions4: null,
      chartDatas5: null,
      chartOptions5: null,
      chartDatas6: null,
      chartOptions6: null,
      chartDatas7: null,
      chartOptions7: null,
      chartDatas8: null,
      chartOptions8: null,
      chartDatas9: null,
      chartOptions9: null,
      chartDatas10: null,
      chartOptions10: null,
      chartDatas11: null,
      chartOptions11: null,
      chartDatas12: null,
      chartOptions12: null,
      executeDateTime: null,
      count1: null,
      count2: null,
      count3: null,
      count4: null,
      count5: null,
      //自動更新
      // // interval
      // dashboardInterval: null,
      // // interval数
      // dashboardCount: 0,
    }
  },

  created() {
    this.goRefresh()
    ////自動更新
    //this.intervalDashboardCheck()
  },

  computed: {
    detailCtrlInfo() {
      let info = this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
      return info
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
  },

  methods: {
    getNoticeCount,
    goRefresh() {
      init(this)
      getNoticeCount(this)
    },
    // //自動更新
    // intervalDashboardCheck() {
    //   this.dashboardInterval = setInterval(() => {
    //     init(this)
    //     //getNoticeCount(this)
    //     this.dashboardCount++
    //     console.log(
    //       'RefreshDashboard(' + this.dashboardCount + ')==================='
    //     )
    //     this.stopDashboardCheck()
    //   }, 3000)
    // },
    // stopDashboardCheck() {
    //   if (this.dashboardCount >= 3) {
    //     console.log('StopDashboard1===================')
    //     clearInterval(this.dashboardInterval)
    //     this.dashboardCount = 0
    //   }
    // },
  },

  beforeDestroy() {
    // //自動更新
    // console.log('StopDashboard2===================')
    // this.dashboardCount = 0
    // clearInterval(this.dashboardInterval)
  },
}
</script>

<style lang="scss" scoped>
ul.item {
  list-style: none;
  width: 100%;
}
li.item {
  display: inline-block;
  margin: 10px;
}
ul.datetime {
  list-style: none;
  text-align: right;
}
ul.countGroup1 li {
  list-style: none;
}
ul.countGroup1 li:before {
  // content: '\ffed';
  content: '\200B';
}
</style>
