<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <div class="tab-group">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="langInfo"
            :inputComponents="inputComponents"
          ></BaseTabGroup>
        </div>
      </template>
      <template #main> </template>
    </DetailPageLayout>
    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="goHome"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="update-modal"
      :columnInfos="messageInfo"
      :message="updateMessage"
      @confirm="update()"
    />
  </div>
</template>

<script>
import { getListOptions, openMsgModal, openSubModal } from '@/common/Common.js'
import { MSG_TYPE } from '@/common/const.js'

export default {
  name: 'langSelect',

  props: {},

  data() {
    return {
      langInfo: {
        lang: this.$store.getters['authentication/getLanguage'],
      },
      updateMessage: null,
    }
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.LANG_SELECT)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.LANG_SELECT)(
        'detail_info'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](
            this.SCREEN_ID.LANG_SELECT
          ),
        },
      ]
    },
    langs() {
      return getListOptions(this.MASTER_CODE.LANGUAGE)
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 更新ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.update,
            onClickCallback: () => {
              this.goUpdate()
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },
    inputComponents() {
      return [
        {
          label: this.detailInfo.title.label,
          description: this.detailInfo.message.label,
          inputComponents: [
            {
              // 言語
              type: 'select',
              id: 'lang',
              columnInfo: this.detailInfo.lang,
              options: this.langs,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
          ],
        },
      ]
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.update.label
      )
    },
  },

  methods: {
    async goUpdate() {
      this.updateMessage = this.$store.getters['init/getMessage'](
        'YZ00MG994Q',
        this.detailCtrlInfo.update.label
      )
      openSubModal(this, 'update-modal')
    },

    async update() {
      this.$bvModal.hide('update-modal')

      const validated = await this.$refs['tab-group'].$refs[
        'base-tab-group'
      ].validate()
      if (!validated) return

      this.$store.dispatch('init/setServiceLoading')
      this.$store
        .dispatch('authentication/selectLang', this.langInfo)
        .then(() => {
          return this.$store.dispatch('init/loadInitInfo', this.langInfo.lang)
        })
        .then((initInfo) => {
          console.log(initInfo)
          this.$router.push({ name: this.SCREEN_ID.DASHBOARD })
        })
        .catch((e) => {
          let message
          if (e.response == undefined) {
            message = this.$store.getters['init/getMessage']('YZ00MG946E')
          } else {
            message = e.response.data.message
          }
          openMsgModal(this, MSG_TYPE.E, message)
        })
        .finally(() => {
          this.$store.dispatch('init/setServiceUnLoading')
        })
    },

    goHome() {
      this.$router.push({ name: this.SCREEN_ID.DASHBOARD })
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style lang="sass" scoped></style>
